import React from "react";

import Modal from 'react-modal';

import './style.scss';

const STYLES = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.55)',
  },
  content: {
    top: 40,
    left: 0,
    bottom: 0,
    right: 0,
    padding: 0,
    border: 0,
    borderRadius: 0,
  }
}

const BaseModal = ({
  isOpen,
  title,
  close,
  onAfterClose,
  imageName,
  onRequestClose,
  color,
  children
}) =>
  <Modal
    style={STYLES}
    className="Modal-Content"
    isOpen={isOpen}
    appElement={document.getElementById('root')}
    closeTimeoutMS={500}
    onAfterClose={onAfterClose}
    onRequestClose={onRequestClose}
  >
    <div className={`Modal-Header ${color}`}>
      <div className="text">
        <h1>{title}</h1>
      </div>
      <div className="button">
        <p onClick={close}>x</p>
      </div>
    </div>
    <div className="Modal-Body">
      {children}
    </div>
  </Modal>

export { BaseModal };

import React from "react";

import { AnimationOnScroll } from 'react-animation-on-scroll';

import './style.scss';

const Title = ({text, color, subtitle, animated=true}) =>
  <div className="Title">
    {animated
      ? <AnimationOnScroll animateOnce={true} animateIn="animate__slideInRight">
          <h1 className={`${color}`}>{text}</h1>
          {subtitle ? <p className={`${color}`}>{subtitle}</p> : null}
        </AnimationOnScroll>
      : <div>
          <h1 className={`${color}`}>{text}</h1>
          {subtitle ? <p className={`${color}`}>{subtitle}</p> : null}
        </div>
    }
  </div>

export { Title };

import React, { useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";

import { BaseModal } from "./../../atoms";

import './style.scss';

const ModalText = () => {
  const { t } = useTranslation();

  return(
    <div>
      <h1>{t("Matthias.modalText.st1")}</h1>
      <p>{t("Matthias.modalText.p1")}</p>
      <h1>{t("Matthias.modalText.st2")}</h1>
      <ul>
        <li>{t("Matthias.modalText.p2")}</li>
        <li>{t("Matthias.modalText.p3")}</li>
        <li>{t("Matthias.modalText.p4")}</li>
        <li>{t("Matthias.modalText.p5")}</li>
        <li>{t("Matthias.modalText.p6")}</li>
        <li>{t("Matthias.modalText.p7")}</li>
        <li>{t("Matthias.modalText.p8")}</li>
        <li>{t("Matthias.modalText.p9")}</li>
        <li>{t("Matthias.modalText.p10")}</li>
      </ul>
      <h1>{t("Matthias.modalText.st3")}</h1>
      <ul>
        <li>{t("Matthias.modalText.p11")}</li>
        <li>{t("Matthias.modalText.p12")}</li>
        <li>{t("Matthias.modalText.p13")}</li>
      </ul>
    </div>
  )
}

const Matthias = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return(
    <div className="Matthias">
      <BaseModal
        isOpen={openModal}
        title="Matthias Klaus"
        close={() => setOpenModal(false)}
        imageName={"matthias-image"}
        onRequestClose={() => setOpenModal(false)}
        color={"brown-red"}
      >
        <ModalText />
      </BaseModal>
      <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
        <div className="Content-Container"  onClick={() => setOpenModal(true)}>
          <div className="hidden-desktop">
            <div className="Matthias-Image"></div>
            <div className="Matthias-Text">
              <p>{t("Matthias.text")}</p>
              <button>{t("viewMore")}</button>
            </div>
          </div>
          <div className="Info-Container hidden-mobile">
            <div className="left-content">
              <div className="Matthias-Text">
                <p>{t("Matthias.text")}</p>
              </div>
            </div>
            <div className="Matthias-Image"></div>
          </div>
          <div className="Middle hidden-mobile">
            <div className="text">{t("viewMore")}</div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}

export { Matthias };

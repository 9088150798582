import React, { useState } from "react";

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from "react-i18next";

import { BaseModal } from "./../../atoms";

import './style.scss';

const Text = () => {
  const { t } = useTranslation();

  return(
    <>
      <p>{t("App.text.p1")}</p>
      <p>{t("App.text.p2")}</p>
      <p>{t("App.text.p3")}</p>
      <p>{t("App.text.p4")}</p>
      <p>{t("App.text.p5")}</p>
    </>
  )
}

const ModalText = () => {
  const { t } = useTranslation();

  return(
    <div>
      <h1>{t("App.modalText.st1")}</h1>
      <p>{t("App.modalText.p1")}</p>
      <p>{t("App.modalText.p2")}</p>
      <p>{t("App.modalText.p3")}</p>
      <p>{t("App.modalText.p4")}</p>
      <p>{t("App.modalText.p5")}</p>
      <p>{t("App.modalText.p6")}</p>
      <h1>{t("App.modalText.st2")}</h1>
      <p>{t("App.modalText.p7")}</p>
      <p>{t("App.modalText.p8")}</p>
      <h1>{t("App.modalText.st3")}</h1>
      <p>{t("App.modalText.p9")}</p>
      <h1>{t("App.modalText.st4")}</h1>
      <p>{t("App.modalText.p10")}</p>
    </div>
  )
}

const AppSection = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return(
    <div className="AppSection">
      <BaseModal
        isOpen={openModal}
        title="IMTO App"
        close={() => setOpenModal(false)}
        imageName={"matthias-image"}
        onRequestClose={() => setOpenModal(false)}
        color={"green-mint"}
      >
        <ModalText />
      </BaseModal>
      <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
        <div className="Content-Container"  onClick={() => setOpenModal(true)}>
          <div className="hidden-desktop">
            <div className="Matthias-Text">
              <Text />
              <button>{t("viewMore")}</button>
            </div>
          </div>
          <div className="Info-Container hidden-mobile">
            <div className="left-content">
              <div className="Matthias-Text">
                <Text />
              </div>
            </div>
          </div>
          <div className="Middle hidden-mobile">
            <div className="text">{t("viewMore")}</div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}

export { AppSection };

import React from "react";
import { useTranslation } from "react-i18next";

import { LangSwitch } from "./../../atoms";

import logo from './../../images/logo.png';
import video from './../../videos/banner.mp4';
import './style.scss';

const VideoBanner = () => {
  const { t } = useTranslation();

  return(
    <div className="VideoBanner">
      <video playsInline autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>
      <div className="Content">
        <img src={logo} alt="logo" />
        <h1>Imto</h1>
        <p>{t("VideoBanner.lineOne")}</p>
        <p>{t("VideoBanner.lineTwo")}</p>
        <p>{t("VideoBanner.lineThree")}</p>
        <LangSwitch />
      </div>
    </div>
  )
}
  

export { VideoBanner };

import React from "react";

import { Link } from "react-router-dom";
import {
  MdOutlineArrowBackIosNew
} from 'react-icons/md';

import './style.scss';

const Policy = () =>
  <div className="Policy">
    <div className="Container">
      <div className="text-center">
        <h2>MANUAL DE POLÍTICAS Y PROCEDIMIENTOS PARA EL TRATAMIENTO DE DATOS PERSONALES DE IMTO S.A.S</h2>
      </div>

      <h2>1. IDENTIFICACIÓN DEL RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</h2>
      <p><strong>IMTO S.A.S.</strong>, referida en adelante en este documento como IMTO, es una sociedad comercial colombiana con NIT 901556276-0. Tiene su domicilio principal en la ciudad de Bogotá D.C. (República de Colombia) y su dirección es Av. Jimenez 8-74 Of. 514 Edificio Sucre PH. Su teléfono de contacto es 314 821 6109 y su correo electrónico de contacto es  matthiasklaus@gmx.ch</p>

      <h2>2. DEFINICIONES</h2>
      <p>Para efectos de la ejecución del presente documento y de conformidad con la normatividad legal colombiana vigente, serán aplicables las siguientes definiciones:</p>
      <ol type="a">
        <li><strong>Autorización</strong>: consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales.</li>
        <li><strong>Aviso de privacidad</strong>: comunicación verbal o escrita generada por el Responsable del Tratamiento dirigida al Titular para el Tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del Tratamiento que se pretende dar a los datos personales.</li>
        <li><strong>Base de Datos</strong>: conjunto organizado de datos personales que sea objeto de Tratamiento.</li>
        <li><strong>Dato personal</strong>: cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</li>
        <li><strong>Dato público</strong>: es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y aquel que no sea semiprivado, privado o sensible. Son públicos, entre otros, los datos relativos al estado civil de las personas, a su profesión u oficio, a su calidad de comerciante o de servidor público y aquellos que puedan obtenerse sin reserva alguna. Por su naturaleza, los datos públicos pueden estar contenidos, entre otros, en registros públicos, documentos públicos, gacetas y boletines oficiales.</li>
        <li><strong>Dato privado</strong>: es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</li>
        <li><strong>Datos sensibles</strong>: aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminación, tales como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas o filosóficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido político o que garanticen los derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual y los datos biométricos.</li>
        <li><strong>Encargado del Tratamiento</strong>: persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento.</li>
        <li><strong>Responsable del Tratamiento</strong>: persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos.</li>
        <li><strong>Titular</strong>: persona natural cuyos datos personales sean objeto de Tratamiento.</li>
        <li><strong>Transmisión</strong>: tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del Responsable.</li>
        <li><strong>Transferencia</strong>: la transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.</li>
        <li><strong>Tratamiento</strong>: cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión de los mismos.</li>
      </ol>
  
      <h2>3. TRATAMIENTO, VIGENCIA Y FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h2>
      <p>La vigencia de las bases de datos personales y su tratamiento dependerá de las finalidades respectivas para las cuales se recolectaron los datos y de la normatividad legal aplicable en cada caso.</p>
      <p>El tratamiento de los datos personales se podrá desarrollar directamente por IMTO o por medio de terceros en Colombia o el exterior, caso en el cual se les deberá transmitir los datos respectivos, respetando en todo caso las disposiciones legales pertinentes.</p>
      <p>IMTO podrá recolectar, almacenar, clasificar, actualizar, transmitir, transferir, suprimir y en general usar los datos personales para los fines que se informen específica y directamente al titular al momento de la recolección, y para las finalidades que a continuación se señalan.</p>
      <h3>3.1 Finalidades generales para los titulares de datos personales:</h3>
      <ol type="a">
        <li>Para el desarrollo y cumplimiento del objeto social de IMTO y de cualquier obligación legal o contractual de IMTO con el titular de los datos personales.</li>
        <li>Para la preparación (incluida la verificación de antecedentes y la veracidad de la información proporcionada), celebración, ejecución, gestión y liquidación de  contratos con IMTO. Estos contratos pueden ser con el titular de los datos personales o con una persona natural o jurídica a la que el Titular esté o estuvo vinculado, por ser el Titular: empleado, contratista, cliente, entre otros, de esta persona. En caso de ser con una persona a la que está o estuvo vinculado el Titular, quien suministre los datos personales del tercero debe contar con la respectiva autorización de ese Titular.</li>
        <li>Proveer los servicios o los productos requeridos por los usuarios de sitios web, plataformas informáticas o software de cualquier tipo de IMTO.</li>
        <li>Crear y enviar publicidad, mercadeo y promoción en general de IMTO, de sus proveedores, de sus clientes, de empresas con las que tenga algún vínculo contractual, o de los productos, servicios o eventos de cualquiera de los anteriores.</li>
        <li>Informar sobre nuevos productos o servicios, de IMTO o de terceros, y/o sobre cambios en los mismos.</li>
        <li>Organizar, desarrollar y/o invitar a eventos y actividades comerciales, académicas, sociales, culturales o de otro tipo que IMTO considere relevantes para el Titular.</li>
        <li>Efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos, sobre preferencias de consumo, conocimiento sobre el mercado o ciertos productos o servicios, investigaciones o estudios de mercado, académicos o de otro tipo, tanto internos como externos, que sean de interés para IMTO.</li>
        <li>Para adelantar actividades de prospección comercial.</li>
        <li>Para realizar proyecciones de ventas o de consumo.</li>
        <li>Para medir, evaluar y mejorar los productos o servicios que se ofrezcan.</li>
        <li>Gestionar y dar respuesta a trámites y requerimientos (peticiones, quejas y reclamos).</li>
        <li>La gestión y el desarrollo de las actividades administrativas, contables, financieras de IMTO, incluida facturación, recaudo de cartera, verificación y reporte en centrales de riesgo, registros contables, pago de nómina, entre otras.</li>
        <li>Para contactar, enviar cotizaciones, devoluciones, garantías, intercambio de documentos comerciales, y en general para todas las actuaciones, actividades y procesos relacionados, vinculados o referidos con clientes, contratistas o proveedores de la empresa.</li>
        <li>Para el cumplimiento de las obligaciones estatutarias y legales de IMTO, incluidas las que son en relación con los socios y administradores de la misma, tales como la citación a reuniones, el envío de información sobre la empresa, la preservación de un registro de estas personas, el ejercicio de sus derechos tales como el derecho de inspección, entre otros.</li>
        <li>Para adelantar procesos de restructuración de la sociedad tales como fusiones, adquisiciones, desinversión, entre otros.</li>
        <li>Suministrar a las instituciones estatales y organismos de control respectivos, los datos requeridos para el ejercicio de sus funciones legales.</li>
        <li>Suministrar datos personales a terceros con los cuales IMTO o un tercero contratista de esta, tenga una relación contractual y que sea necesario entregárselos para el cumplimiento de obligaciones legales, del objeto del contrato o de alguna de las finalidades acá establecidas.</li>
        <li>Llevar a cabo procesos de auditoría interna o externa.</li>
        <li>Suministrar, compartir o enviar de cualquier manera sus datos personales a empresas filiales, vinculadas, o subordinadas de IMTO, ubicadas en Colombia o el exterior, en el evento que dichas compañías requieran la información para los fines aquí indicados.</li>
        <li>Con fines de seguridad de los bienes de IMTO y de las personas y bienes que se encuentran e ingresan en instalaciones de IMTO o donde haya dispuesto mecanismos de seguridad. Para ello se podrá, entre otras medidas, registrar por escrito datos personales de quienes ingresan, tales como nombre, cédula o teléfono, así como realizar grabaciones audiovisuales, de solo video o de solo audio. La voz y la imagen son datos sensibles y por tanto su autorización es facultativa.</li>
        <li>Informar sobre actualizaciones de fondo del presente documento de políticas u otros documentos relevantes para el titular de los datos personales.</li>
        <li>Para el cumplimiento de protocolos de bioseguridad establecidos por las autoridades competentes.</li>
      </ol>
      <h3>3.2 Finalidades específicas solo para clientes y potenciales clientes de IMTO:</h3>
      <ol type="a">
        <li>Para la inscripción de posibles nuevos clientes y adelantar todos los trámites comerciales, administrativos y legales requeridos para su vinculación como clientes de IMTO.</li>
        <li>Para la segmentación de los clientes o posibles clientes.</li>
        <li>Desarrollar actividades de atención al cliente y soporte técnico.</li>
        <li>Realizar análisis del consumo de los clientes, así como sus comportamientos en relación con los servicios prestados.</li>
      </ol>
      <h3>3.3 Finalidades específicas solo para empleados, contratistas o posibles empleados o contratistas:</h3>
      <ol type="a">
        <li>La preparación, celebración, gestión, desarrollo, control y terminación de las relaciones laborales o contractuales de todo tipo con IMTO.</li>
        <li>Para todas las actividades necesarias para la selección de personal para IMTO, incluyendo la realización de entrevistas y pruebas, así como la verificación de antecedentes de la persona y su pertinencia para el cargo, verificación de la veracidad de la información proporcionada, clasificación y almacenamiento de la hoja de vida y demás información personal de la persona para futuras convocatorias o necesidades de vinculación de personal, entre otras.</li>
        <li>Para comunicarse con el Titular, en razón a las funciones, actividades y obligaciones desempeñadas en razón a un contrato suscrito con IMTO o un tercero, y en general para el cumplimiento del objeto de dicho contrato.</li>
        <li>Para fines históricos, de registro y de archivo interno, así como el cumplimiento de obligaciones legales como la certificación de la vinculación contractual sostenida con IMTO por una persona.</li>
        <li>Para el cumplimiento de la normatividad relacionada con el Sistema de Gestión de Seguridad y Salud en el Trabajo, incluyendo entre otros, el registro de los participantes en los simulacros.</li>
        <li>Llevar registro y control de insumos, materiales y elementos de cualquier tipo que se le entreguen al Titular.</li>
        <li>Para procurar el cumplimiento del Reglamento Interno de Trabajo, el control del desempeño del empleado o contratista, adelantar las investigaciones y procesos disciplinarios necesarios y en general buscar garantizar el cumplimiento de las obligaciones contractuales o legales de contratistas y empleados.</li>
        <li>Registrar y mantener actualizada la información de contratistas, empleados y/o pensionados (activos e inactivos) en las bases de datos de IMTO, con el fin de garantizar alguna de las finalidades establecidas en este documento, o al momento de recolectar el dato o en la ley.</li>
        <li>El desarrollo de las demás actividades propias de gestión humana y de contratistas en general, que no hayan sido específicamente mencionadas hasta el momento, tales como: afiliaciones y trámites con entidades del sistema general de seguridad social; contacto con el empleado o contratista, sus familiares o personas de contacto en caso de emergencia; actividades sociales, de bienestar y capacitación; pago de salarios, honorarios o cualquier otro dinero adeudado, así como las actividades administrativas asociadas a esto, como expedir recibos de pago o el envío de comprobantes de pago; realización de los exámenes médicos laborales; cumplimiento de convenios con fondos de empleados; y las demás obligaciones contractuales o legales que se tengan.</li>
      </ol>
      <h3>3.4 Disposiciones generales en relación con las finalidades:</h3>
      <p><strong>3.4.1</strong> Para el cumplimiento de cualquier finalidad del tratamiento de los datos personales, se podrá utilizar software, dispositivos o herramientas de cualquier tipo que empleen inteligencia artificial, tales como de aprendizaje automático (machine learning), entre otras.</p>
      <p><strong>3.4.2</strong> Así mismo, para establecer comunicación con el Titular para el cumplimiento de cualquier finalidad del tratamiento de los datos personales, se podrá utilizar cualquier medio físico o digital disponible para comunicarse, tales como correos electrónicos, mensajería instantánea (Whatsapp, Telegram, etc.), redes sociales o mensajes de texto, dependiendo de la necesidad y disponibilidad.</p>

      <h2>4. DERECHOS DE LOS TITULARES DE DATOS PERSONALES</h2>
      <p>Son derechos de los Titulares de datos personales:</p>
      <ol type="a">
        <li>Conocer, actualizar y rectificar sus datos personales frente a IMTO o los Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.</li>
        <li>Solicitar prueba de la autorización otorgada a IMTO salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en la normatividad legal vigente.</li>
        <li>Ser informado por IMTO o el Encargado del Tratamiento, previa solicitud, respecto del uso que se les ha dado a sus datos personales.</li>
        <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la normatividad legal vigente. Para esto deberá agotar previamente el requisito de procedibilidad, es decir el trámite de consulta o reclamo ante IMTO (punto 5. y 6. de este documento).</li>
        <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento IMTO o el Encargado han incurrido en conductas contrarias a la Constitución y la ley.</li>
        <li>Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</li>
      </ol>
    
      <h2>5. CONSULTAS</h2>
      <p>Los Titulares o sus causahabientes o sus representantes o apoderados debidamente autorizados, podrán consultar los datos personales del Titular que repose en cualquier base de datos de IMTO. La consulta se podrá efectuar enviando un mensaje al correo electrónico matthiasklaus@gmx.ch suministrando como mínimo la siguiente información:</p>
      <ul>
        <li><strong>Nombre completo</strong>.</li>
        <li><strong>Datos de contacto</strong> (dirección física y/o electrónica y teléfonos de contacto).</li>
        <li><strong>Identificación del titular y de ser el caso, de su causahabiente, representante o apoderado</strong>. Para esto deberá aportar copia del documento de identidad. Si se trata de apoderado o representante, deberá además aportar copia de su documento y del respectivo poder o documento que acredite debidamente tal calidad.</li>
        <li><strong>Solicitud clara de los datos personales que desea consultar, bien sea ésta consulta de todos los datos o de algunos datos personales en particular.</strong></li>
      </ul>        
      <p>En caso de no cumplir con alguno de los anteriores requisitos, dentro de los cinco (5) días siguientes a la recepción de la consulta, IMTO requerirá al interesado para que corrija lo necesario. Si pasados dos (2) meses de hecho el requerimiento de subsanación este no se ha atendido debidamente, se entenderá desistida la consulta.</p>
      <p>De cumplir con los requisitos mínimos exigidos, la consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado expresando los motivos de la demora y señalando la fecha en que se atenderá, la cual en ningún caso será superior a los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>

      <h2>6. RECLAMOS</h2>
      <p>Los Titulares o sus causahabientes o sus representantes o apoderados debidamente autorizados que consideren que la información contenida en una base de datos de IMTO debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en este documento o en la ley, podrán presentar un reclamo enviando un mensaje al correo electrónico matthiasklaus@gmx.ch suministrando como mínimo la siguiente información:</p>
      <ul>
        <li><strong>Nombre completo</strong>.</li>
        <li><strong>Datos de contacto</strong> (dirección física y/o electrónica y teléfonos de contacto).</li>
        <li><strong>Identificación del titular y de ser el caso, de su causahabiente, representante o apoderado</strong>. Para esto deberá aportar copia del documento de identidad. Si se trata de apoderado o representante, deberá además aportar copia de su documento y del respectivo poder o documento que acredite debidamente tal calidad.</li>
        <li><strong>Motivo(s)/hecho(s) que dan lugar al reclamo con una breve descripción del derecho que desea ejercer</strong> (conocer, actualizar, rectificar, solicitar prueba de la autorización otorgada, revocarla, etc.)</li>
        <li><strong>Documentos que se quieren hacer valer con el reclamo</strong>.</li>
      </ul>
      <p>En caso de no cumplir con alguno de los anteriores requisitos, dentro de los cinco (5) días siguientes a la recepción del reclamo IMTO requerirá al interesado para que corrija lo necesario. Si pasados dos (2) meses de hecho el requerimiento de subsanación este no se ha atendido debidamente, se entenderá desistido el reclamo.</p>
      <p>En caso de que IMTO no sea competente para resolver el reclamo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.</p>
      <p>Cumplidos con los requisitos mínimos exigidos para el reclamo, en un término no mayor a dos (2) días hábiles desde su recepción se incluirá en la base de datos una leyenda que diga "reclamo en trámite" y el motivo del mismo. Esta leyenda deberá mantenerse hasta que el reclamo sea decidido.</p>
      <p>Así mismo, cumplidos con los requisitos mínimos exigidos, el reclamo se atenderá en un término máximo de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</p>

      <h2>7. ÁREA ENCARGADA DE LA ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS</h2>
      <p>El gerente de IMTO es el encargado de dar trámite a las solicitudes de los titulares para hacer efectivos sus derechos. El canal para efectuar cualquier solicitud es el correo electrónico matthiasklaus@gmx.ch, de acuerdo a lo indicado en los puntos 5 y 6 del presente documento. Una vez se recepcione la consulta o reclamo, el gerente la resolverá directamente o la remitirá a un asesor jurídico de IMTO, siempre dentro de los términos acá establecidos.</p>

      <h2>8. REMISIÓN LEGAL</h2>
      <p>En lo no establecido en el presente documento se estará a lo dispuesto en la normatividad legal vigente.</p>

      <h2>9. VIGENCIA Y CAMBIOS</h2>
      <p>El presente documento tiene vigencia desde el 11 de abril de 2022.</p>

      <div className="text-center">
        <p><strong>IMTO S.A.S.</strong></p>
        <p>NIT 901556276-0</p>
      </div>

      <div className="link-to-back">
        <Link to="/">Regresar a ImTo</Link>
      </div>
    </div>
  </div>

export { Policy };

import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { AnimationOnScroll } from 'react-animation-on-scroll';

import { BaseModal, Card } from "./../../atoms";

import './style.scss';

const FirstContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <p><Trans i18nKey="InfoCards.CardOne.p1" /></p>
      <p>{t("InfoCards.CardOne.p2")}</p>
      <p><Trans i18nKey="InfoCards.CardOne.p3" /></p>
      <h1>{t("InfoCards.CardOne.st1")}</h1>
      <p>{t("InfoCards.CardOne.p4")}</p>
      <p>{t("InfoCards.CardOne.p5")}</p>
      <p>{t("InfoCards.CardOne.p6")}</p>
      <ul>
        <li><strong>{t("InfoCards.CardOne.p7")}</strong></li>
        <li><strong>{t("InfoCards.CardOne.p8")}</strong></li>
        <li><strong>{t("InfoCards.CardOne.p9")}</strong></li>
      </ul>
    </div>
  )
}

const SecondContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <p>{t("InfoCards.CardTwo.p1")}</p>
      <p>{t("InfoCards.CardTwo.p2")}</p>
      <p>{t("InfoCards.CardTwo.p3")}</p>
      <p>{t("InfoCards.CardTwo.p4")}</p>
      <p>{t("InfoCards.CardTwo.p5")}</p>
      <p>{t("InfoCards.CardTwo.p6")}</p>
      <p>{t("InfoCards.CardTwo.p7")}</p>
    </div>
  )
}

const ThirdContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <h1>{t("InfoCards.CardThree.st1")}</h1>
      <p>{t("InfoCards.CardThree.p1")}</p>
      <p>{t("InfoCards.CardThree.p2")}</p>
      <p>{t("InfoCards.CardThree.p3")}</p>
      <h1>{t("InfoCards.CardThree.st2")}</h1>
      <p>{t("InfoCards.CardThree.p4")}</p>
      <p>{t("InfoCards.CardThree.p5")}</p>
      <p>{t("InfoCards.CardThree.p6")}</p>
      <p>{t("InfoCards.CardThree.p7")}</p>
    </div>
  )
}

const FourthContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <p>{t("InfoCards.CardFour.p1")}</p>
      <p>{t("InfoCards.CardFour.p2")}</p>
      <p>{t("InfoCards.CardFour.p3")}</p>
      <p>{t("InfoCards.CardFour.p4")}</p>
      <p>{t("InfoCards.CardFour.p5")}</p>
      <p>{t("InfoCards.CardFour.p6")}</p>
      <p>{t("InfoCards.CardFour.p7")}</p>
      <p>{t("InfoCards.CardFour.p8")}</p>
    </div>
  )
}

const FifthContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <p className="text-center">
        <strong>{t("InfoCards.CardFive.p1")}</strong>
      </p>
      <p className="text-center">
        <strong>{t("InfoCards.CardFive.p2")}</strong>
      </p>
      <p className="text-center">
        <strong>{t("InfoCards.CardFive.p3")}</strong>
      </p>
      <p className="text-center">
        <strong>{t("InfoCards.CardFive.p4")}</strong>
      </p>
      <h1>{t("InfoCards.CardFive.st1")}</h1>
      <ul>
        <li>{t("InfoCards.CardFive.p5")}</li>
        <li>{t("InfoCards.CardFive.p6")}</li>
        <li>{t("InfoCards.CardFive.p7")}</li>
        <li>{t("InfoCards.CardFive.p8")}</li>
        <li>{t("InfoCards.CardFive.p9")}</li>
      </ul>
      <p>{t("InfoCards.CardFive.p10")}</p>
      <h1>{t("InfoCards.CardFive.st2")}</h1>
      <ul>
        <li>{t("InfoCards.CardFive.p11")}</li>
        <li>{t("InfoCards.CardFive.p12")}</li>
        <li>{t("InfoCards.CardFive.p13")}</li>
        <li>{t("InfoCards.CardFive.p14")}</li>
        <li>{t("InfoCards.CardFive.p15")}</li>
      </ul>
      <p><strong>{t("InfoCards.CardFive.p16")}</strong></p>
      <ul>
        <li>{t("InfoCards.CardFive.p17")}</li>
        <li>{t("InfoCards.CardFive.p18")}</li>
        <li>{t("InfoCards.CardFive.p19")}</li>
        <li>{t("InfoCards.CardFive.p20")}</li>
      </ul>
      <h1>{t("InfoCards.CardFive.st3")}</h1>
      <ul>
        <li>{t("InfoCards.CardFive.p21")}</li>
        <li>{t("InfoCards.CardFive.p22")}</li>
      </ul>
      <p>{t("InfoCards.CardFive.p23")}</p>
      <p><strong>{t("InfoCards.CardFive.p24")}</strong></p>
    </div>
  )
}

const CONTENTS = [
  <FirstContent />,
  <SecondContent />,
  <ThirdContent />,
  <FourthContent />,
  <FifthContent />
]

const InfoCards = () => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);

  const isSelected = index => {
    return index === selected;
  }

  const handleSelect = index => {
    setSelected(index);
    setOpenModal(true);
  }

  const CARDS = [
    {
      index: 1,
      text: t("InfoCards.CardOne.title"),
      color: "green-mint"
    },
    {
      index: 2,
      text: t("InfoCards.CardTwo.title"),
      color: "green-mint"
    },
    {
      index: 3,
      text: t("InfoCards.CardThree.title"),
      color: "red"
    },
    {
      index: 4,
      text: t("InfoCards.CardFour.title"),
      color: "red"
    },
    {
      index: 5,
      text: t("InfoCards.CardFive.title"),
      color: "blue"
    }
  ];

  return(
    <div className="InfoCards">
      <BaseModal
        isOpen={openModal}
        title={CARDS[selected - 1]?.text || ''}
        close={() => setOpenModal(false)}
        onAfterClose={() => setSelected(null)}
        imageName={'card-' + CARDS[selected - 1]?.index + '-image'}
        onRequestClose={() => setOpenModal(false)}
        color={CARDS[selected - 1]?.color}
      >
        {CONTENTS[selected - 1]}
      </BaseModal>

      {CARDS.map((c, index) => (
        <AnimationOnScroll
          animateOnce={true}
          delay={1000 * index}
          animateIn="animate__backInRight">
          <Card
            key={c.index}
            index={c.index}
            text={c.text}
            selected={isSelected(c.index)}
            handleClick={() => handleSelect(c.index)}
            color={c.color}
          />
        </AnimationOnScroll>
      ))}
    </div>
  );
}

export { InfoCards };

import React from "react";

import { AnimationOnScroll } from 'react-animation-on-scroll';

import { PublicationCard } from "./../../atoms";

import './style.scss';

const articles = [
  {
    siteName: "Diario La República",
    title: "Empresario suizo crea firma Imto, para promover inversión de finca raíz en Europa",
    type: "article",
    url: "https://www.larepublica.co/empresas/empresario-suizo-crea-la-firma-imto-para-promover-inversion-de-finca-raiz-en-europa-3465265",
    image: "https://img.lalr.co/cms/2022/10/07185017/Matthias-Klaus-Empresario.png?size=xl&amp;ratio=r40_21&amp;f=jpg",
    image2: "https://img.lalr.co/cms/2022/10/07185017/Matthias-Klaus-Empresario.png?size=sm&amp;ratio=sq&amp;f=jpg",
    imageAlt: "Foto de empresario suizo",
    description: "Matthias Klaus sostiene que inversiones en finca raíz en Alemania, Francia y España pueden alcanzar rentabilidad de hasta 15% anual."
  },
  {
    siteName: "El Tiempo",
    title: "Empresario creó firma en Colombia para invertir en finca raíz en Europa",
    type: "article",
    url: "https://www.eltiempo.com/economia/empresas/empresario-creo-firma-en-colombia-para-invertir-en-finca-raiz-en-europa-721364",
    image: "https://www.eltiempo.com/files/og_paste_img/uploads/2022/11/28/6384e9d576043.jpeg",
    image2: "https://img.lalr.co/cms/2022/10/07185017/Matthias-Klaus-Empresario.png?size=sm&amp;ratio=sq&amp;f=jpg",
    imageAlt: "Matthias Klaus",
    description: "También anunciaron la creación de una aplicación que permite hacer avalúos de manera gratuita."
  },
]

const Publications = () =>
  <div className="Publications">
    <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
      {articles.map(a => <PublicationCard publication={a} />)}
    </AnimationOnScroll>
  </div>

export { Publications };

import React from "react";

import './style.scss';

const Card = ({index, text, selected, handleClick, color}) =>
  <div className={`Card ${selected ? 'selected' : color}`} onClick={handleClick}>
    <div className="Card-Header">
      <h1>{'0' + index}</h1>
    </div>
    <div className={`Card-Image Card-${index}`}>
    </div>
    <div className="Card-Footer">
      <p>{text}</p>
    </div>
  </div>

export { Card };

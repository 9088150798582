import React from "react";

import './style.scss';

const PublicationCard = ({publication}) => {
  const { description, image, imageAlt, siteName, title, url } = publication;

  const openPublication = () => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  }

  return(
    <div className="PublicationCard"  onClick={() => openPublication()}>
      <div className="image-container">
        <img src={image} alt={imageAlt} />
      </div>
      <div className="content">
        <p className="site-name">{siteName}</p>
        <p className="title">{title}</p>
        <p className="description">{description}</p>
        <p className="link">
          <a href={url} target="_blank">ver más</a>
        </p>
      </div>
    </div>
  );
}

export { PublicationCard };

import React from "react";

import { Divider, Footer, Policy, Title } from "./../atoms";

const PrivacyPolicy = () =>
  <div>
    <div className="section dark">
      <Title
        text="Política de Privacidad"
        color="light"
        subtitle="www.imto.com.co"
        animated={false}
      />
    </div>
    <div className="section light">
      <Policy />
    </div>
    <Footer />
  </div>

export { PrivacyPolicy };

import React from "react";

import { Divider, Footer, Terms, Title } from "./../atoms";

const LegalNotice = () =>
  <div>
    <div className="section dark">
      <Title
        text="Aviso Legal"
        color="light"
        subtitle="www.imto.com.co"
        animated={false}
      />
    </div>
    <div className="section light">
      <Terms />
    </div>
    <Footer />
  </div>

export { LegalNotice };

import React, { useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";

import { BaseModal } from "./../../atoms";

import './style.scss';

const FirstContent = () => {
  const { t } = useTranslation();

  return(
    <div>
      <p>{t("Questions.firsQuestion.modalText.p1")}</p>
      <p>{t("Questions.firsQuestion.modalText.p2")}</p>
    </div>
  )
}

const Questions = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleSelect = index => {
    setSelected(index);
    setOpenModal(true);
  }

  const CONTENTS = [
    {
      title: t("Questions.firsQuestion.title"),
      content: <FirstContent />,
      image: "colombia-image",
    }
  ]

  return(
    <div className="Questions">
      <BaseModal
        isOpen={openModal}
        title={CONTENTS[selected - 1]?.title || ''}
        close={() => setOpenModal(false)}
        onAfterClose={() => setSelected(null)}
        imageName={CONTENTS[selected - 1]?.image || ''}
        onRequestClose={() => setOpenModal(false)}
        color={"green-mint"}
      >
        {CONTENTS[selected - 1]?.content}
      </BaseModal>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
        <div className="Question-Container" onClick={() => handleSelect(1)}>
          <div className="Question-Image colombia-image">
          </div>
          <div className="Question-Content">
            <div className="Question-Title green-mint">
              <h1>{t("Questions.firsQuestion.title")}</h1>
            </div>
            <div className="Question-Text">
              <p>{t("Questions.firsQuestion.text")}</p>
              <button className="hidden-desktop">{t("viewMore")}</button>
            </div>
          </div>
          <div className="Question-Hover hidden-mobile">
            <div className="text">{t("viewMore")}</div>
          </div>
        </div>
      </AnimationOnScroll>
    </div>
  );
}

export { Questions };

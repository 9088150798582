import React from "react";
import { useTranslation } from "react-i18next";

import './style.scss';

const lngs = {
  es: { nativeName: 'es', flag: '🇨🇴' },
  en: { nativeName: 'en', flag: '🇺🇸' },
};

const LangSwitch = () => {
  const { i18n } = useTranslation();

  return(
    <div className="LangSwitch">
      {Object.keys(lngs).map((lng) => (
        <div
          className={`opt ${i18n.resolvedLanguage === lng ? 'selected' : ''}`}
          key={lng}
          onClick={() => i18n.changeLanguage(lng)}>
          <p className="flag">{lngs[lng].flag}</p>
          <p>{lngs[lng].nativeName}</p>
        </div>
      ))}
    </div>
  )
}
  

export { LangSwitch };

import React from "react";
import { useTranslation } from "react-i18next";

import { AnimationOnScroll } from 'react-animation-on-scroll';

import './style.scss';

const Intro = () => {
  const { t } = useTranslation();

  return(
    <div className="Intro">
      <div className="Container">
        <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
          <div className="reasons">
            <p>{t("Intro.p1")}</p>
            <p>{t("Intro.p2")}</p>
            <p>{t("Intro.p3")}</p>
            <p>{t("Intro.p4")}</p>
            <p>{t("Intro.p5")}</p>
            <p>{t("Intro.p6")}</p>
          </div>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp">
          <h1>{t("Intro.subtitle")}</h1>
          <p>{t("Intro.p7")}</p>
          <p>{t("Intro.p8")}</p>
        </AnimationOnScroll>
      </div>
    </div>
  )
}

export { Intro };

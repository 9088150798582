import React from "react";

import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
  FaXingSquare
} from 'react-icons/fa';
import { useTranslation } from "react-i18next";

import './style.scss';

const FB_LINK = "https://www.facebook.com/profile.php?id=100085852194928";
const IN_LINK = "https://www.instagram.com/imto_inversiones/";
const TW_LINK = "https://www.twitter.com";
const XI_LINK = "https://www.xing.com/pages/imto-s-a-s";

const Footer = () => {
  const { t } = useTranslation();

  return(
    <div className="Footer">
      <div className="container">
        <div className="column">
          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeIn">
            <h1>{t("Footer.legal")}</h1>
            <ul>
              <li>
                <Link to="/terminos-y-condiciones">
                  {t("Footer.termsAndConditions")}
                </Link>
              </li>
              <li>
                <Link to="/politica-de-privacidad">
                  {t("Footer.privacyPolicy")}
                </Link>
              </li>
              <li><Link to="/aviso-legal">{t("Footer.legalNotice")}</Link></li>
            </ul>
          </AnimationOnScroll>
        </div>
        <div className="column">
          <AnimationOnScroll
            initiallyVisible={false}
            animateIn="animate__pulse"
            duration={1}
          >
            <h1>{t("Footer.contactUs")}</h1>
            <p>contacto@imto.com.co</p>
            <p>314 821 6109 (Colombia)</p>
            <p>+41 799 567 330 ({t("Footer.international")})</p>
            <p>{t("Footer.address")}</p>
          </AnimationOnScroll>
        </div>
        <div className="column">
          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeIn">
            <h1>{t("Footer.followUs")}</h1>
            <div className="icons">
              <a href={FB_LINK} target="_blank"><FaFacebookSquare /></a>
              <a href={IN_LINK} target="_blank"><FaInstagramSquare /></a>
              <a href={TW_LINK} target="_blank"><FaTwitterSquare /></a>
              <a href={XI_LINK} target="_blank"><FaXingSquare /></a>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  )
}

export { Footer };

import React from "react";

import { useTranslation } from "react-i18next";

import {
  AppSection,
  Divider,
  Footer,
  Intro,
  Title,
} from "./../atoms";
import {
  InfoCards,
  Matthias,
  Questions,
  Publications,
  VideoBanner
} from "./../molecules";

const Home = () => {
  const { t } = useTranslation();

  return(
    <div>
      <VideoBanner />
      <div className="section dark">
        <Title text="IMTO" color="light"/>
        <Divider side="right" color="green-mint"/>
        <Intro />
        <InfoCards />
      </div>
      <div className="section bg-image">
        <div className="overlay"></div>
        <Title
          text="Matthias Klaus"
          color="light"
          subtitle={t("Matthias.subtitle")}
        />
        <Divider side="left" color="red-wine"/>
        <Matthias />
      </div>
      <div className="section dark">
        <Questions />
      </div>
      <div className="section light">
        <div className="overlay"></div>
        <Title
          text="IMTO App"
          color="light"
          subtitle={t("App.subtitle")}
        />
        <Divider side="left" color="red-wine"/>
        <AppSection />
      </div>
      <div className="section dark">
        <Title text={t("Publications.title")} color="light"/>
        <Divider side="right" color="green-mint"/>
        <Publications />
      </div>
      <Footer />
    </div>
  )
}

export { Home };

import React from "react";

import { Link } from "react-router-dom";
import {
  MdOutlineArrowBackIosNew
} from 'react-icons/md';

import './style.scss';

const Terms = () =>
  <div className="Terms">
    <div className="Container">
      <h2>1. DISPOSICIONES GENERALES</h2>
      <p><strong>1.1</strong>{" "}El presente documento de términos y condiciones de uso, referido de ahora en adelante como <strong>TÉRMINOS Y CONDICIONES</strong>, regula el acceso y uso del sitio web www.imto.com.co, referido en adelante como <strong>IMTO</strong> o <strong>WEB</strong>. Quién acceda o use IMTO, será referido de ahora en adelante como <strong>USUARIO</strong> y en plural <strong>USUARIOS</strong>.</p>
      <p><strong>1.2</strong>{" "}AL ACCEDER O USAR <strong>IMTO</strong>, USTED NO SOLO SE CONVIERTE EN USUARIO SINO TAMBIÉN SE ESTÁ OBLIGANDO A LO ESTABLECIDO EN ESTE DOCUMENTO. POR TANTO, AL SEGUIR ADELANTE MANIFIESTA ENTENDER Y ACEPTAR ESTE DOCUMENTO, DE LO CONTRARIO, <u><strong>SI NO LO ENTIENDE O NO LO ACEPTA, DEBE EVITAR ACCEDER O USAR IMTO Y SALIR INMEDIATAMENTE DE ESTA.</strong></u></p>
      <p><strong>1.3</strong>{" "}<strong>IMTO S.A.S.</strong>, referida de ahora en adelante como <strong>PROPIETARIA</strong>, es una sociedad comercial colombiana con NIT 901.556.276-0, con domicilio en la ciudad de Bogotá D.C. y correo electrónico de contacto matthiasklaus@gmx.ch</p>
      <p><strong>1.4</strong>{" "}IMTO es un sitio web que permite conocer los productos y servicios que comercializa la PROPIETARIA particularmente en el sector inmobiliario, tales como avalúos, compra y venta de inmuebles, administración de propiedades, acompañamiento a proyectos inmobiliarios, entre otros., todos los cuales serán referidos en adelante como SERVICIOS.</p>
      <p><strong>1.5</strong>{" "}La finalidad de la WEB es meramente informativa.</p>
      <p><strong>1.6</strong>{" "}Los TÉRMINOS Y CONDICIONES se rigen por la normatividad legal colombiana y en caso de conflicto se deberá primero intentar resolver de manera directa entre las partes involucradas y en caso de que esto falle, se deberá acudir a los juzgados de la ciudad de Bogotá D.C. (República de Colombia).</p>

      <h2>2. PROPIEDAD INTELECTUAL Y DERECHOS DE IMAGEN</h2>
      <p><strong>2.1</strong>{" "}La PROPIETARIA es la titular de los derechos de propiedad intelectual sobre IMTO, incluyendo el código fuente y el material auxiliar. A excepción de la imagen de fondo de la página de inicio y a menos que se indique lo contrario en otros contenidos, la PROPIETARIA es titular igualmente de los demás derechos de propiedad intelectual de los contenidos disponibles en IMTO, como signos distintivos ( enseñas comerciales, marcas, etc.) o cualquier obra protegida por derecho de autor.</p>
      <p><strong>2.2</strong>{" "}La PROPIETARIA tiene la autorización sobre los derechos de imagen de las personas que aparezcan en la WEB. El hecho de aparecer en la WEB no le concede a terceros autorización sobre los derechos de imagen de estas personas y cualquier uso no autorizado por parte de un tercero puede constituir una infracción legal a dichos derechos de imagen, además de los derechos de propiedad intelectual de ser el caso.</p>
      <p><strong>2.3</strong>{" "}Cualquier USUARIO o tercero que considere que en IMTO hay material que viola sus derechos de propiedad intelectual, de imagen o cualquier otro derecho o los derechos de algún tercero o que se está facilitando dicha violación, debe inmediatamente ponerlo en conocimiento de la PROPIETARIA por medio del correo electrónico mencionado en el punto 1.3, adjuntando las pruebas de dicha situación. Una vez puesto en conocimiento de la PROPIETARIA dicha situación, esta podrá tomar las acciones que considere necesarias, incluyendo bajar los CONTENIDOS respectivos de IMTO, o abstenerse de hacer algo si considera que la persona que informa de la situación no tiene razón, caso en el cual la persona denunciante podrá acudir ante los jueces de acuerdo con lo dispuesto en el punto 1.6.</p>

      <h2>3. USO DE  IMTO</h2>
      <p><strong>3.1</strong>{" "}IMTO debe usarse conforme a los TÉRMINOS Y CONDICIONES y, si es el caso, a cualquier otro contrato o documento adicional suscrito por la PROPIETARIA con el USUARIO. En todo caso, cualquier acceso o uso de IMTO debe ser de buena fe y conforme a la normatividad legal vigente en la República de Colombia.</p>
      <p><strong>3.2</strong>{" "}Los USUARIOS solo pueden usar los contenidos de la WEB de acuerdo con lo establecido en los TÉRMINOS Y CONDICIONES y en la ley colombiana. Cualquier uso diferente está prohibido y podrá tener no solo consecuencias en la WEB, como el bloqueo de la misma, sino jurídicas por parte de PROPIETARIA o de algún USUARIO en caso de vulnerarse algún derecho.</p>
      <p><strong>3.3</strong>{" "}Los USUARIOS tienen expresamente prohibido usar o acceder a IMTO para cometer, inducir o facilitar a otra persona la comisión de delitos informáticos (Ley 1273 de 2009 y las normas que la reemplacen, adicionen o modifiquen) o cualquier otro delito, o para causar cualquier daño o perjuicio de forma ilegal, bien sea en contra de la PROPIETARIA, otro USUARIO o contra cualquier otra persona natural o jurídica.</p>
      <p><strong>3.4</strong>{" "}Si un USUARIO o un tercero advierte cualquier violación de las normas jurídicas vigentes en Colombia por parte de cualquier persona en relación con IMTO, deberá avisar inmediatamente a PROPIETARIA al correo electrónico de contacto mencionado en el punto 1.3.</p>
      <p><strong>3.5</strong>{" "}En caso de detectar cualquier violación a los TÉRMINOS Y CONDICIONES, la PROPIETARIA podrá bloquear al USUARIO y tomar las demás medidas técnicas, administrativas y/o jurídicas necesarias. Esto sin perjuicio de las acciones jurídicas que puedan tomar los USUARIOS o terceros que se puedan ver afectados por la violación de los TÉRMINOS Y CONDICIONES.</p>

      <h2>4. DATOS PERSONALES</h2>
      <p><strong>4.1</strong>{" "}El documento con las políticas de datos personales de la PROPIETARIA hace parte integral de estos TÉRMINOS Y CONDICIONES, será referido en adelante en este documento como POLÍTICAS DE DATOS PERSONALES, y podrá ser consultado en: <Link to="/politica-de-privacidad">www.imto.com.co/politica-de-privacidad</Link>.</p>
      <p><strong>4.2</strong>{" "}La PROPIETARIA puede recolectar y usar datos personales de los USUARIOS al acceder y/o usar de alguna manera IMTO, tales como la dirección IP. En todo caso, el tratamiento de los datos personales estará regido por las POLÍTICAS DE DATOS PERSONALES.</p>

      <h2>5. RESPONSABILIDAD</h2>
      <p><strong>5.1</strong>{" "}Si bien la PROPIETARIA hará lo posible por mantener en funcionamiento IMTO, no se garantiza la disponibilidad y el correcto funcionamiento de la misma las 24 horas del día los 365 días del año y en consecuencia, no será responsable de cualquier daño o perjuicio que se cause por la disponibilidad de IMTO.</p>
      <p><strong>5.2</strong>{" "}Si bien la PROPIETARIA procura proporcionar IMTO sin errores, los USUARIOS y entienden y aceptan que no se puede garantizar que esta esté 100% libres de errores. En consecuencia, la PROPIETARIA no responderá por los posibles daños y perjuicios de cualquier tipo que se puedan producir al USUARIO o a terceros, por el mal funcionamiento o cualquier error de IMTO o de los contenidos de la WEB.</p>
      <p><strong>5.3</strong>{" "}Los USUARIOS deberán mantener el dispositivo por medio del cual acceden o usan IMTO en un buen estado, con las actualizaciones al día y con las medidas de seguridad necesarias para prevenir incidentes de seguridad o cualquier otra afectación a IMTO, la PROPIETARIA o a ellos mismos.</p>

      <h2>6. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES</h2>
      <p><strong>6.1</strong>{" "}Los TÉRMINOS Y CONDICIONES podrán ser modificados en cualquier momento sin previo aviso por parte de la PROPIETARIA. Cada vez que haya alguna modificación a los TÉRMINOS Y CONDICIONES, que no sea de forma, la PROPIETARIA creará un aviso en IMTO para avisar al USUARIO que hubo modificaciones. No obstante, se recomienda a los USUARIOS revisar los TÉRMINOS Y CONDICIONES cada vez que se use IMTO para verificar, a través de la cláusula 7.2, si ha habido alguna modificación.</p>
      <p><strong>6.2</strong>{" "}En caso de que la PROPIETARIA modifique los TÉRMINOS Y CONDICIONES y el USUARIO continúe usando IMTO, se entenderá que entiende y acepta estas modificaciones.</p>

      <h2>7. VIGENCIA</h2>
      <p><strong>7.1</strong>{" "}Si alguna de las cláusulas o disposiciones de los TÉRMINOS Y CONDICIONES es declarada nula o no es aplicable de acuerdo a la legislación colombiana vigente, esto no afectará la fuerza y vigencia de las demás disposiciones de este documento.</p>
      <p><strong>7.2</strong>{" "}Esta versión de los TÉRMINOS Y CONDICIONES tienen vigencia desde el 15 de junio de 2022.</p>

      <div className="link-to-back">
        <Link to="/">Regresar a ImTo</Link>
      </div>
    </div>
  </div>

export { Terms };
